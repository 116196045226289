import React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import get from 'lodash.get';

import { buildVideo, buildVideoNewsArticle, buildLiveVideoObjectLD } from 'lib/LDJson';
import { getCanonical, securify } from 'lib/urlUtils';
import { shareImage } from 'lib/social';
import { video as videoPropType } from 'lib/CustomPropTypes';
import getTitle from 'lib/getTitle';

import AMPHTMLLink from 'components/AMP/HTMLLink';
import { resolveOnTheShowMetadata } from './metadataUtils';

function constructMetaData(video, vertical) {
  const {
    description,
    headline,
    hidden,
    nativeAd,
    primaryImage,
    url,
  } = video;

  const imageUrl = get(primaryImage, 'url.primary', {});

  const onTheShowMetadata = resolveOnTheShowMetadata(
    video.taxonomy,
    vertical,
    video.mpxMetadata?.guid,
  );

  return (
    <Head>
      <title>{getTitle(headline, vertical)}</title>
      <meta name="description" content={description.seo || description.primary} />
      <link rel="canonical" href={getCanonical(url)} />
      {(nativeAd || hidden) && <meta name="robots" content="noindex, nofollow" />}

      <meta property="og:url" content={securify(url.primary)} />
      <meta property="og:title" content={headline.social || headline.primary} />
      <meta property="og:description" content={description.primary || description.seo} />
      <meta property="og:image" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:secure_url" content={shareImage(imageUrl, vertical, 'og')} />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:type" content="article" />

      <meta name="twitter:image" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:image:src" content={shareImage(imageUrl, vertical, 'twitter')} />
      <meta name="twitter:title" content={headline.social || headline.primary} />
      <meta name="twitter:description" content={description.primary || description.seo} />
      <meta name="twitter:card" content="summary_large_image" />

      <meta name="thumbnail" content={shareImage(imageUrl, vertical)} />

      {onTheShowMetadata}
    </Head>
  );
}

function VideoMetadata({ video, vertical }) {
  const isLiveVideo = video?.videoType === 'nonBroadcastLiveVideo' || video?.source?.name === 'Live Video';
  const videoDuration = !isLiveVideo ? video.duration : null;

  return (
    <>
      <Head>
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildVideo(video, vertical))}`,
          }}
        />
        <script
          type="application/ld+json"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `${JSON.stringify(buildVideoNewsArticle(video, vertical))}`,
          }}
        />
        <script
          type="application/ld+json"
          data-testid="canonical-live-video"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: JSON.stringify(buildLiveVideoObjectLD({
              title: video.headline?.seo,
              description: video.description?.seo,
              duration: videoDuration,
              thumbnailUrl: video.primaryImage?.url?.primary,
              uploadDate: new Date(video.datePublished),
              author: video.publisher?.name,
              startDate: new Date(video.dateBroadcast),
              url: video.url?.primary,
              videoAssets: video.videoAssets,
            },
            isLiveVideo)),
          }}
        />
      </Head>

      <AMPHTMLLink content={video} vertical={vertical} />

      {constructMetaData(video, vertical)}
    </>
  );
}

VideoMetadata.propTypes = {
  video: videoPropType.isRequired,
  vertical: PropTypes.string.isRequired,
};

export { VideoMetadata };
